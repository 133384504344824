<template>
  <reservation-header :login="login" :verifClientIsOver="verifClientIsOver" :headerTitle="headerTitle"
    :headerSecondeTitle="headerSecondeTitle" :userInformation="userInformation" :isValidUser="isValidUser"
    :isUserConnected="isUserConnected">
  </reservation-header>
  <v-layout v-if="!isMobile">
    <div style="background-color: #ECEFF1;height: calc(100vh - 82px);position: absolute;top:82px;width: 300px;"
      class="pa-0">
      <drawer-informations style="padding-top:100px" :scrollToId="scrollToId" :step="step"
        :selectedMaterial="selectedMaterial" :selectedAccessoire="selectedAccessoire" :selectedAgency="selectedAgency"
        :selectedPaymentType="selectedPaymentType" :selectedPaymentAmount="selectedPaymentAmount"></drawer-informations>
    </div>
  </v-layout>
  <div class="main-vue" style="overflow: auto;height: 87vh;">
    <div v-if="verifClientIsOver && materialIsLoading == false" :style="{ 'margin-left': isMobile ? '0px' : '300px' }">
      <v-col>
        <div>
          <material-selection id="material" :familyList="familyList" :materialList="materialList"
            :setMaterial="setMaterial" :selectedMaterial="selectedMaterial"
            :userInformation="userInformation"></material-selection>
          <contact-form id="contact" v-if="selectedMaterial && selectedMaterial.qty <= 0" :setMaterial="setMaterial"
            :selectedMaterial="selectedMaterial" :userInformation="userInformation"
            :showSnackBarFunct="showSnackBarFunct"></contact-form>
          <accessoire-selection id="accessoire"
            v-if="step >= 2 && selectedMaterial && selectedMaterial.accessories && selectedMaterial.accessories.length != 0"
            :selectedMaterial="selectedMaterial"
            :updateSelectedAccessoireByIndex="updateSelectedAccessoireByIndex"></accessoire-selection>
          <agency-selection id="agency" v-if="step >= 2 && selectedMaterial" :selectedMaterial="selectedMaterial"
            :agencyListe="agencyListe" :setAgency="setAgency" :setMeetId="setMeetId"
            :selectedAgency="selectedAgency"></agency-selection>
          <payment-type-selection id="payment" v-if="step >= 4 && selectedMaterial && selectedMaterial.subscriptions"
            :selectedMaterial="selectedMaterial" :setSelectedPaymentType="setSelectedPaymentType"
            :selectedPaymentType="selectedPaymentType"></payment-type-selection>
          <subscription-selection id="subscription" v-if="step >= 5" :setSelectedPaymentAmount="setSelectedPaymentAmount"
            :selectedPaymentType="selectedPaymentType"></subscription-selection>
        </div>
        <div v-if="step >= 6">
          <div id="files">
            <div v-if="isValidUser == false && showAllReservationForm == false" style="" align="center">
              <v-col>
                <v-col style="background-color: #ECEFF1;border:solid 3px red; border-radius: 5px;">
                  <v-col align="left" class="mb-3">
                    <h4>
                      Un compte client est nécessaire pour aller plus loin dans votre réservation
                    </h4>
                    <h5>
                      <br>
                      Pour finaliser votre location différents types de fichiers nécessaires :
                      <br> - IBAN VALIDE
                      <br> - CARTE NATIONAL D'IDENTITÉ / PASSEPORT EN COURS DE VALIDITÉ
                      <br> - JUSTIFICATIF DE DOMICILE DE MOINS DE 3 MOIS
                      <br> - Rib
                      <br> - ATTESTATION D'ASSURANCE RESPONSABILITÉ CIVILE
                    </h5>
                  </v-col>
                  <client-connection :connected="connected" :userInformation="userInformation" :isValidUser="isValidUser"
                    :isUserConnected="isUserConnected" :login="login"
                    :verifClientIsOver="verifClientIsOver"></client-connection>
                </v-col>
              </v-col>
            </div>
            <div v-else :style="!isValidUser ? 'pointer-events: none; filter: blur(5px)' : ''">
              <files-verifications :resetFilesToLoad="resetFilesToLoad" :addFilesToLoad="addFilesToLoad"
                :filesInfo="filesInfo" :filesToLoad="filesToLoad" :selectedPaymentAmount="selectedPaymentAmount"
                :selectedPaymentType="selectedPaymentType" :filesToLoadListe="filesToLoadListe"
                :verifAllFiel="verifAllFiel" :validAllMandatoryFiles="validAllMandatoryFiles"
                :isValidUser="isValidUser"></files-verifications>
            </div>
          </div>
          <div id="recap">
            <resume-validation v-if="isValidUser == true && step >= 7" :filesToLoad="filesToLoad"
              :style="{ display: isValidUser == false && showAllReservationForm == false ? 'none' : 'block' }"
              :selectedMaterial="selectedMaterial" :selectedPaymentAmount="selectedPaymentAmount"
              :selectedPaymentType="selectedPaymentType" :selectedAccessoire="selectedAccessoire"
              :selectedAgency="selectedAgency" :selectedMeetId="selectedMeetId" :userInformation="userInformation"
              :selectedMeetDate="selectedMeetDate" :materialList="materialList"></resume-validation>
          </div>
        </div>
      </v-col>
    </div>
    <v-col cols="12" v-else align="center">
      <basic-loading style="margin-top:30vh"></basic-loading>
    </v-col>
  </div>
  <div>
  </div>
  <v-snackbar v-model="showSnackBar.value" timeout="5000" :color="showSnackBar.color">
    <h3>
      {{ showSnackBar.text }}
    </h3>
  </v-snackbar>
</template>

<script>
import reservationHook from "@/hooks/reservationHook"
import clientHook from "@/hooks/clientHook"

import ReservationHeader from '@/components/header/header-general.vue'
import MaterialSelection from '@/components/reservation/material_selection.vue'
import AccessoireSelection from '@/components/reservation/accessoire_selection.vue'
import AgencySelection from '@/components/reservation/agency_selection.vue'
import PaymentTypeSelection from '@/components/reservation/payment_type_selection.vue'
import SubscriptionSelection from '@/components/reservation/subscription_selection.vue'
import ResumeValidation from '@/components/reservation/resume_validation.vue'
import FilesVerifications from '@/components/reservation/files_verifications.vue'
import DrawerInformations from "@/components/reservation/drawer_informations.vue"
import ContactForm from "@/components/reservation/contact_form.vue"

import BasicLoading from '@/components/basic-loading.vue'

import ClientConnection from '@/components/home/redirection_compte.vue'

import { ref } from "@vue/reactivity";

export default {
  data() {
    return {
      showSnackBar: {}
      // verifAllFiel: true
    }
  },
  setup() {
    // const publishableKey = ref("pk_test_ahgPfDAEug0DVswMYTTPShWU00RWXNOa9y")
    const loading = ref(false)
    const lineItems = ref([
      {
        price: 'price_1MtrYKHZJyALc7GWaZOe5RzE', // The id of the one-time price you created in your Stripe dashboard
        quantity: 1,
      },
    ])
    // const successURL = ref('https://reservation.ogalo-saumurvaldeloire.fr/api/stripe/succes')
    // const cancelURL = ref('https://reservation.ogalo-saumurvaldeloire.fr/api/stripe/cancel')
    const showAllReservationForm = ref(false)

    const {
      headerTitle,
      materialList,
      setMaterial,
      selectedMaterial,
      updateSelectedAccessoireByIndex,
      agencyListe,
      setAgency,
      selectedAgency,
      step,
      selectedMeetKey,
      setSelectedMeetKey,
      setMeetId,
      selectedPaymentType,
      setSelectedPaymentType,
      setSelectedPaymentAmount,
      validAllMandatoryFiles,
      selectedPaymentAmount,
      selectedAccessoire,
      headerSecondeTitle,
      scrollToId,
      selectedMeetId,
      materialIsLoading,
      verifAllFiel,
      selectedMeetDate,
      filesToLoadListe,
      familyList,
      filesInfo,
      filesToLoad,
      addFilesToLoad,
      resetFilesToLoad
    } = reservationHook();

    const {
      login,
      userInformation,
      isValidUser,
      isUserConnected,
      verifClientIsOver
    } = clientHook()

    return {
      headerTitle,
      materialList,
      setMaterial,
      selectedMaterial,
      updateSelectedAccessoireByIndex,
      agencyListe,
      setAgency,
      selectedAgency,
      step,
      selectedMeetKey,
      setSelectedMeetKey,
      setMeetId,
      selectedPaymentType,
      setSelectedPaymentType,
      setSelectedPaymentAmount,
      validAllMandatoryFiles,
      isValidUser,
      isUserConnected,
      verifClientIsOver,
      login,
      selectedPaymentAmount,
      selectedAccessoire,
      headerSecondeTitle,
      scrollToId,
      selectedMeetId,
      userInformation,
      // publishableKey,
      loading,
      lineItems,
      // successURL,
      // cancelURL,
      showAllReservationForm,
      materialIsLoading,
      verifAllFiel,
      selectedMeetDate,
      filesToLoadListe,
      familyList,
      filesInfo,
      filesToLoad,
      addFilesToLoad,
      resetFilesToLoad
    }
  },
  mounted() {
    // const script1 = document.createElement('script');
    // script1.src = 'https://static.payzen.eu/static/js/krypton-client/V4.0/stable/kr-payment-form.min.js';
    // script1.setAttribute('kr-public-key', '51173854:publickey_y5Cq8gHsXzM5Fc8usfN8lhwX2PMUshO9pvTS67L1kI37M');
    // script1.setAttribute('kr-post-url-success', 'saumur.accen.fr');
    // document.head.appendChild(script1);

    // const link = document.createElement('link');
    // link.rel = 'stylesheet';
    // link.href = 'https://static.payzen.eu/static/js/krypton-client/V4.0/ext/neon-reset.css';
    // document.head.appendChild(link);

    // const script2 = document.createElement('script');
    // script2.src = 'https://static.payzen.eu/static/js/krypton-client/V4.0/ext/neon.js';
    // document.head.appendChild(script2);
  },
  methods: {
    connected() {
      this.isUserConnected()
      this.showAllReservationForm = true
    },
    showSnackBarFunct(elem) {
      this.showSnackBar.color = elem.color
      this.showSnackBar.text = elem.text
      this.showSnackBar.value = true
    }
  },
  computed: {
    isMobile() {
      return this.$vuetify.display.mobile
    }
  },
  watch: {
    // step(val) {

    //   console.log("step", val);
    //   console.log("avant", document.head);
    //   if (val != 7) {
    //     console.log("remove");
    //     const headElements = document.head.children;

    //     // Parcourir les éléments du head en sens inverse (de la fin vers le début)
    //     for (let i = headElements.length - 1; i >= 0; i--) {
    //       const element = headElements[i];

    //       // Vérifier si l'élément contient le mot spécifié
    //       if (element.outerHTML.includes('payzen')) {
    //         element.parentNode.removeChild(element); // Supprimer l'élément du head
    //       }
    //     }
    //     // document.getElementById('krtoolbar').style.display = 'none'
    //   } else {
    //     const script1 = document.createElement('script');
    //     script1.src = 'https://static.payzen.eu/static/js/krypton-client/V4.0/stable/kr-payment-form.min.js';
    //     script1.setAttribute('kr-public-key', '51173854:publickey_y5Cq8gHsXzM5Fc8usfN8lhwX2PMUshO9pvTS67L1kI37M');
    //     script1.setAttribute('kr-post-url-success', 'saumur.accen.fr');
    //     script1.setAttribute('data-delete', '');
    //     document.head.appendChild(script1);

    //     const link = document.createElement('link');
    //     link.rel = 'stylesheet';
    //     link.href = 'https://static.payzen.eu/static/js/krypton-client/V4.0/ext/neon-reset.css';
    //     link.setAttribute('data-delete', '');
    //     document.head.appendChild(link);

    //     const script2 = document.createElement('script');
    //     script2.src = 'https://static.payzen.eu/static/js/krypton-client/V4.0/ext/neon.js';
    //     script2.setAttribute('data-delete', '');
    //     document.head.appendChild(script2);
    //   }
    //   console.log("apres", document.head);

    // },
    userInformation(val) {
      console.log("userInformation", val);
      if (val != undefined) {
        let familyTab = {
          "TROTTINETTE": "web_nb_reservation_trottinette",
          "VAE": "web_nb_reservation_vae",
          "VELO": "web_nb_reservation_velo",
          "VOITURE SANS PERMIS": "web_nb_reservation_voiture_sans_permis",
        }

        console.log("val", val);
        console.log("selectedMaterial", this.selectedMaterial);
        if (val != undefined && this.selectedMaterial != undefined && val[familyTab[this.selectedMaterial.famille]] > 0) {
          this.showSnackBarFunct({ text: 'Vous disposez déjà d\'une location en cours dans cette catégorie', color: 'red' })
          this.setMaterial(undefined)
        }
        //  else {
        //   this.setMaterial(undefined)
        //   this.showSnackBarFunct({ text: 'Vous disposez déjà d\'une location en cours dans cette catégorie', color: 'red' })
        // }
      }
    },
    isValidUser(val) {
      if (val == true) {
        this.showAllReservationForm = true
      }
      else {
        this.showAllReservationForm = false
        if (this.step >= 6) {
          this.step = 6
        }
      }
    }
  },
  components: {
    ClientConnection,
    ReservationHeader,
    AccessoireSelection,
    AgencySelection,
    MaterialSelection,
    PaymentTypeSelection,
    SubscriptionSelection,
    ResumeValidation,
    FilesVerifications,
    DrawerInformations,
    BasicLoading,
    ContactForm
  }
}
</script>
